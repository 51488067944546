import React, { useEffect, useState } from 'react';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import customFonts from '../src/fontsPDF/fonts';
import Exo from './assets/Exo/Exo-VariableFont_wght.ttf';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.vfs = {
    ...customFonts,
    'Exo': Exo
}

const PdfGenerator = ({ messages, setShowForm, sentImageUrls= [], setShowModal }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);

    useEffect(() => {
        const phraseSpecifique = '//Début du prérapport//';
        const messageContientPhrase = messages.some(message =>
            typeof message.content === 'string' && message.content.includes(phraseSpecifique)
        );

        setButtonStatus(messageContientPhrase);

        if (messageContientPhrase && !isDownloaded) {
            generatePdf();
            generatePdfClient()
            setIsDownloaded(true);
            setShowForm(true);
        }
        if (isDownloaded) {
            setShowModal(true)

        }
    }, [messages, isDownloaded, setShowForm]);


    /**
     * Génération du PDF sur serveur pour gestionnaire
     * @returns {Promise<void>}
     */
    const generatePdf = async () => {

        const lastMessages = messages.slice(Math.max(messages.length - 25, 0));
        let globalContentPreRapport = '';
        //cherche le contenu du pré-rapport dans les messages
        lastMessages.forEach((message) => {
            // Assure-toi que `message.content` est une chaîne de caractères avant de la diviser
            if (typeof message.content === 'string') {
                const messageLines = message.content.split("\n");
                let isPreRapport = false;
                const debutRapport = '//Début du prérapport//';
                const finRapport = '//Fin du prérapport//';
                let contentPreRapport = '';

                messageLines.forEach(line => {
                    if (line.includes(debutRapport)) {
                        isPreRapport = true;
                        setButtonStatus(true);
                    } else if (line.includes(finRapport)) {
                        isPreRapport = false;
                        // Pas besoin de `return` ici car nous voulons continuer à lire les lignes du message actuel
                    } else if (isPreRapport && !line.includes(finRapport)) {
                        contentPreRapport += line + '\n';
                    }
                });

                // Si du contenu de pré-rapport a été trouvé dans le message actuel, ajoute-le au contenu global de pré-rapport
                if (contentPreRapport) {
                    globalContentPreRapport += contentPreRapport + '\n'; // Sépare les contenus de pré-rapport de différents messages par une nouvelle ligne

                }
            }
        });
        const titres = [
            "0. Identification du client :",
            "I. Rappel des faits :",
            "II. Mesures conservatoires et prévention des risques :",
            "III. Dommages :",
            "IV. Analyse technique :",
            "V. Causes :",
            "VI. Conséquences :",
            "VII. Estimation des coûts :",
            "VIII. Conclusion :",
            "IX. Suites à donner :",
        ];

        const regexPattern = titres.map(titre => `\\b${titre}\\s+`).join('|');
        const regex = new RegExp(regexPattern, 'gm');
        const partiesRapport = globalContentPreRapport.split(regex);
        const startPosition = partiesRapport[1] ? partiesRapport[1].indexOf("Nom :") : 0;
        const infoClient = partiesRapport[1] ? partiesRapport[1].substring(startPosition) : '';

        // Sépare le texte en lignes, puis crée des paires clé-valeur
        const lignes = infoClient.split('\n');

        let infoClientTableau = lignes.map(ligne => {
            // Pour chaque ligne, sépare la clé et la valeur sur ":"
            let [cle, ...reste] = ligne.split(':');
            let valeur = reste.join(':').trim(); // Gère le cas où il y a des ':' dans la valeur

            return {[cle.trim()]: valeur};
        }).filter(obj => Object.keys(obj)[0] !== ''); // Filtre les lignes vides

        
        const formatInfoClientPourTableau = (obj) => {
          
          if (obj) {
        
            return `${Object.keys(obj)[0]}: ${Object.values(obj)[0]}`;
                    
            } else 
            {
            return 'Information non disponible'
            }
        };

        // let nomComplet = infoClientTableau[0] ? formatInfoClientPourTableau(infoClientTableau[0]) : '';
        // let nom = nomComplet.split(' ')[1];

        // à faire + tard : en faire un service à part
        const convertImageToDataUrl = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    ctx.drawImage(this, 0, 0);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                };
                img.onerror = reject;
                img.src = url;
            });
        };

        const docDefinition = {
            content: [
                {
                    text: 'Pré-rapport d\'expertise',
                    style: 'header'
                },
                {
                    style: 'tableExample',
                    text: 'Identification du client',
                    table: {
                        body: [
                            [{
                                text: 'Identification du client',
                                style: 'tableHeader',
                                colSpan: 3,
                                alignment: 'center'
                            }, {}, {}],
                            [formatInfoClientPourTableau(infoClientTableau[0]), formatInfoClientPourTableau(infoClientTableau[1]), formatInfoClientPourTableau(infoClientTableau[2])],
                            [formatInfoClientPourTableau(infoClientTableau[3]), formatInfoClientPourTableau(infoClientTableau[4]), {}] // Utilise || '' pour gérer le cas où il n'y a pas de sixième élément
                        ],
                    }
                },
                {
                    text: 'Rappel des faits',
                    style: 'Title'
                },
                {
                    text: partiesRapport[2],
                    lineHeight: 1.5,// introduction
                    alignment: 'justify',
                    margin: [40, 20, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                // {
                //     text: 'Détails du Rapport',
                //     style: 'Title'
                // },
                // {
                //     text: partiesRapport[3], // Inclut le contenu du pré-rapport
                //     lineHeight: 1.5,
                //     alignment: 'justify',
                //     margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                // },
                {
                    text: 'Mesures conservatoires et prévention des risques',
                    style: 'Title'
                },
                {
                    text: partiesRapport[3], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Dommages',
                    style: 'Title'
                },
                {
                    text: partiesRapport[4], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 0, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: partiesRapport[5], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 0, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Causes',
                    style: 'Title'
                },
                {
                    text: partiesRapport[6], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Conséquences',
                    style: 'Title'
                },
                {
                    text: partiesRapport[7], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Estimation des coûts',
                    style: 'Title'
                },
                {
                    text: partiesRapport[8], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Conclusion',
                    style: 'Title'
                },
                {
                    text: partiesRapport[9], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Suites à donner',
                    style: 'Title'
                },
                {
                    text: partiesRapport[10], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },

                // Inclut les images
            ],
            defaultStyle: {
                font: 'Exo'
            },
            styles: {
                header: {
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [40, 0, 40, 20] ,
                    decoration: 'underline'
                },
                tableHeader: {
                    fontSize: 16,
                    bold: true
                },
                tableExample: {
                    margin: [0, 30, 0, 15]
                },
                Title: {
                    fontSize: 15,
                    bold: true,
                    decoration: 'underline',
                    margin:[40, 20,40,10]
                },
                image: {
                    margin: [0, 20, 0, 20], // Ajoute un peu d'espace autour de l'image
                    lineHeight: 1.5,
                    alignment: 'center'
                }
            }
        };

        if (sentImageUrls.length > 0) {
            try {
                Promise.all(sentImageUrls.map(url => convertImageToDataUrl(url)))
                    .then(async dataUrls => {
                        const images = dataUrls.map(url => ({
                            image: url,
                            width: 300, // Ajuste la largeur comme nécessaire
                            // margin: [0, 20, 0, 20], // Ajoute un peu d'espace autour de l'image
                            // lineHeight: 1.5,
                            // align: 'center'
                            style: 'image'
                        }))
                        docDefinition.content.push(...images)

                        const response = await fetch("/pdf", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(docDefinition)
                        });

                        if (!response.ok) throw new Error("Network response was not ok.");

                        const blob = await response.blob();
                        const downloadUrl = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = downloadUrl;
                        link.setAttribute("download", "report.pdf");
                        document.body.appendChild(link);
                        // link.click();
                        link.parentNode.removeChild(link);
                        setIsLoading(false);
                    });
            } catch (error) {
                console.error("Failed to generate PDF:", error);
                setIsLoading(false);
            }
        }
        else {
            try {
                const response = await fetch("/pdf", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(docDefinition)
                });

                if (!response.ok) throw new Error("Network response was not ok.");

                const blob = await response.blob();
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", "report.pdf");
                document.body.appendChild(link);

                link.parentNode.removeChild(link);
                setIsLoading(false);
            } catch (error) {
                console.error("Failed to generate PDF:", error);
                setIsLoading(false);
            }
        }
    }

    /**
     * **********************************************************************************************************************************************************************
     * ********************************************Déclaration client *******************************************************************************************************
     * **********************************************************************************************************************************************************************
     * @param event
     */


    //Fonction qui génère la déclaration coté client.
    const generatePdfClient = (event) => {

        const lastMessages = messages.slice(Math.max(messages.length - 25, 0));
        let globalContentPreRapport = '';
        //cherche le contenu du pré-rapport dans les messages
        lastMessages.forEach((message) => {
            // Assure-toi que `message.content` est une chaîne de caractères avant de la diviser
            if (typeof message.content === 'string') {
                const messageLines = message.content.split("\n");
                let isPreRapport = false;
                const debutRapport = '//Début du prérapport//';
                const finRapport = '//Fin du prérapport//';
                let contentPreRapport = '';

                messageLines.forEach(line => {
                    if (line.includes(debutRapport)) {
                        isPreRapport = true;
                        setButtonStatus(true);
                    } else if (line.includes(finRapport)) {
                        isPreRapport = false;
                        // Pas besoin de `return` ici car nous voulons continuer à lire les lignes du message actuel
                    } else if (isPreRapport && !line.includes(finRapport)) {
                        contentPreRapport += line + '\n';
                    }
                });

                // Si du contenu de pré-rapport a été trouvé dans le message actuel, ajoute-le au contenu global de pré-rapport
                if (contentPreRapport) {
                    globalContentPreRapport += contentPreRapport + '\n'; // Sépare les contenus de pré-rapport de différents messages par une nouvelle ligne

                }
            }
        });
       const titres = [
            "0. Identification du client :",
            "I. Rappel des faits :",
            "II. Mesures conservatoires et prévention des risques :",
            "III. Dommages :",
            "IV. Analyse technique :",
            "V. Causes :",
            "VI. Conséquences :",
            "VII. Estimation des coûts :",
            "VIII. Conclusion :",
            "IX. Suites à donner :",
        ];
        const regexPattern = titres.map(titre => `\\b${titre}\\s+`).join('|');
        const regex = new RegExp(regexPattern, 'gm');
        const partiesRapport = globalContentPreRapport.split(regex);
        const startPosition = partiesRapport[1] ? partiesRapport[1].indexOf("Nom :") : 0;
        const infoClient = partiesRapport[1] ? partiesRapport[1].substring(startPosition) : '';

        // Sépare le texte en lignes, puis crée des paires clé-valeur
        const lignes = infoClient.split('\n');

        let infoClientTableau = lignes.map(ligne => {
            // Pour chaque ligne, sépare la clé et la valeur sur ":"
            let [cle, ...reste] = ligne.split(':');
            let valeur = reste.join(':').trim(); // Gère le cas où il y a des ':' dans la valeur

            return {[cle.trim()]: valeur};
        }).filter(obj => Object.keys(obj)[0] !== ''); // Filtre les lignes vides

        const formatInfoClientPourTableau = (obj) => {
            if (obj) {
                return `${Object.keys(obj)[0]}: ${Object.values(obj)[0]}`;
            } else {
                return 'Info non disponible'
            }
        };

        // let nomComplet = infoClientTableau[0] ? formatInfoClientPourTableau(infoClientTableau[0]) : '';
        // let nom = nomComplet.split(' ')[1];

        // à faire + tard : en faire un service à part
        const convertImageToDataUrl = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = function () {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    canvas.height = this.naturalHeight;
                    canvas.width = this.naturalWidth;
                    ctx.drawImage(this, 0, 0);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                };
                img.onerror = reject;
                img.src = url;
            });
        };

        const docDefinition = {
            content: [
                {
                    text: 'Déclaration de sinistre',
                    style: 'header'
                },
                {
                    style: 'tableExample',
                    text: 'Identification du client',
                    table: {
                        body: [
                            [{
                                text: 'Identification du client',
                                style: 'tableHeader',
                                colSpan: 3,
                                alignment: 'center'
                            }, {}, {}],
                            [formatInfoClientPourTableau(infoClientTableau[0]), formatInfoClientPourTableau(infoClientTableau[1]), formatInfoClientPourTableau(infoClientTableau[2])],
                            [formatInfoClientPourTableau(infoClientTableau[3]), formatInfoClientPourTableau(infoClientTableau[4]), {}] // Utilise || '' pour gérer le cas où il n'y a pas de sixième élément
                        ],
                    }
                },
                {
                    text: 'Rappel des faits',
                    style: 'Title'
                },
                {
                    text: partiesRapport[2],
                    lineHeight: 1.5,// introduction
                    alignment: 'justify',
                    margin: [40, 20, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Dommages',
                    style: 'Title'
                },
                {
                    text: partiesRapport[4], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 0, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                // {
                //     text: partiesRapport[6], // Inclut le contenu du pré-rapport
                //     lineHeight: 1.5,
                //     alignment: 'justify',
                //     margin: [40, 0, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                // },
                {
                    text: 'Causes',
                    style: 'Title'
                },
                {
                    text: partiesRapport[6], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: 'Conséquences',
                    style: 'Title'
                },
                {
                    text: partiesRapport[7], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                {
                    text: '' +
                        'Je certifie conforme cette déclaration de sinistre, et m\'engage à fournir au gestionnaire toute information supplémentaire que j\'estime utile à la ' +
                    'résolution de mon sinistre.' +
                        '',
                    bold: true,
                    background: '#d1d1d1',
                    margin: [20,10,20,10]
                },
                {
                    text:'Prévention des risques',
                    style: 'Title',
                    fontSize: 12

                },
                {
                    text: partiesRapport[3], // Inclut le contenu du pré-rapport
                    lineHeight: 1.5,
                    alignment: 'justify',
                    margin: [40, 10, 40, 10] // Ajoute un peu d'espace autour du texte du pré-rapport
                },
                // Inclut les images
            ],
            styles: {
                header: {
                    fontSize: 20,
                    bold: true,
                    alignment: 'center',
                    margin: [40, 0, 40, 20] ,
                    decoration: 'underline'
                },
                tableHeader: {
                    fontSize: 16,
                    bold: true
                },
                tableExample: {
                    margin: [0, 30, 0, 15]
                },
                Title: {
                    fontSize: 15,
                    bold: true,
                    decoration: 'underline',
                    margin:[40, 20,40,10]
                },
                image: {
                    margin: [0, 20, 0, 20], // Ajoute un peu d'espace autour de l'image
                    lineHeight: 1.5,
                    alignment: 'center'
                }
            }
        };


        if (sentImageUrls.length > 0) {
            try {
                Promise.all(sentImageUrls.map(url => convertImageToDataUrl(url)))
                    .then(async dataUrls => {
                        const images = dataUrls.map(url => ({
                            image: url,
                            width: 300, // Ajuste la largeur comme nécessaire
                            // margin: [0, 20, 0, 20], // Ajoute un peu d'espace autour de l'image
                            // lineHeight: 1.5,
                            // align: 'center'
                            style: 'image'
                        }))
                        docDefinition.content.push(...images)
                        pdfMake.createPdf(docDefinition).download('déclaration.pdf');
                    });
            } catch (error) {
                console.error("Failed to generate PDF:", error);
                setIsLoading(false);
            }
        }
        else {
            pdfMake.createPdf(docDefinition).download('déclaration.pdf');

        }
    }
            return (
                <div className="">

                    <button className=""
                            onClick={generatePdfClient}>
                        <svg className='w-9 h-9 absolute bottom-7 left-[74px] '
                             width="800px"
                             height="800px"
                             viewBox="0 0 512 512"
                             xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fillRule="evenodd">
                                <g fill="#2d547d">
                                    <path
                                        d="M 312.32,165.76 285.01333,132.90667 192,210.56 V 0 H 149.33333 V 210.56 L 56.32,132.90667 29.013333,165.76 170.66667,283.73333 Z M 0,341.33333 H 341.33333 V 384 H 0 Z"/>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            );

    };

export default PdfGenerator;