import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import ReactMarkdown from 'react-markdown';
import Pdfgenerator from "./Pdfgenerator";
import useSendMessage from "./hooks/useSendMessages";
import useImageUpload from "./hooks/useImageUpload";
import Modal from "./Modal";
import AuthForm from "./AuthForm";

function App() {
  const [userName, setUserName] = useState(false);
  const [authToken, setAuthToken] = useState({token: null, email: null})
  const [ showForm ,setShowForm] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sentImageUrls, setSentImageUrls] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [qa, setQa] = useState({});
  const [photosButtons, setPhotosButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isOver, setIsOver] = useState(false);
  //test
  const defaultMessage = {
    role: "assistant",
    content: "Bonjour, je suis Thémis, l'assistant·e qui vous accompagne dans la résolution de vos dommages. \n\n" +
        "Pour m'aider à comprendre au mieux votre situation et procéder efficacement à l'élaboration de votre pré-rapport, pourriez-vous me fournir une description détaillée de ce qui " +
        "s'est passé ? \n\n" +
        "Veuillez inclure, si possible, les circonstances menant à l'incident, les dommages observés et toute autre information que vous jugerez pertinente."
  };
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([defaultMessage]);
  const { uploadedImageUrls,
    setUploadedImageUrls,
    errorMessage,
    handleImageChange,
    removeImage,
    largeImageUrls
  } = useImageUpload();

  const { handleSend, error } = useSendMessage(
      inputValue,
      setInputValue,
      setIsLoading,
      setSentImageUrls,
      setUploadedImageUrls,
      uploadedImageUrls,
      messages,
      setMessages,
      setQa,
      qa
  );
  const [currentQuestion, setCurrentQuestion] = useState(null);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];

    if (lastMessage.role === 'assistant' && lastMessage.content) {
      const normalizedContent = lastMessage.content.toLowerCase();
      setCurrentQuestion(normalizedContent);
      setIsButtonClicked(false);
    }
  }, [messages]);

  useEffect(() => {
    const phraseSpecifique = 'photos';
    const messageContientPhrase = messages.some(message =>
        typeof message.content === 'string' && message.content.includes(phraseSpecifique)
    );
    setPhotosButtons(messageContientPhrase);
  }, [messages]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    const phraseSpecifique = '//Début du prérapport//';
    const messageContientPhrase = messages.some(message =>
        typeof message.content === 'string' && message.content.includes(phraseSpecifique)
    );
  }, [messages]);


  // if (!authToken) {
  //   return <AuthForm setAuthToken={setAuthToken} setUserName={setUserName} />;
  // }
 
  //const handleLogout = () => {
  //  setAuthToken(null);
  //};
  //const userId = localStorage.getItem('userId');
  //<img  className="w-20 h-auto mr-5 mt-5 mb-5" src="https://i.ibb.co/9vRL6tB/axa.webp" alt="axa"/>
            //<img  className="w-auto h-20 mr-5 mt-5 mb-5" src="https://i.ibb.co/vs8Tx01/litige-2.png" alt="litige"/>
             
  return (
      <div className="App relative">
        {error && <p>Une erreur est survenue ; {error.message}.</p>}
        <div className="m-auto min-h-screen w-full ">
          <div id="nav" className="h-auto w-full z-20 flex flex-row justify-between bg-[#265279]">
            <img src="https://i.ibb.co/8bBzCQ2/AI2.png" className=" w-auto h-20 my-auto mx-2" alt=""/>
            <div>
               <img className="w-auto h-20 mr-5 mt-5 mb-5" src="https://i.ibb.co/NWCwcZx/civis-2.png" alt="civis"/>
            </div>
          </div>
          <div className="background"></div>
          <div id="chatContainer" className="md:max-h-[75vh]  px-4 w-full m-auto overflow-y-auto scroll-smooth">
            {messages.map((message, index) => (
                <div key={index}
                     className={`flex space-x-3 ${message.role === 'user' ? 'justify-end' : 'justify-start'} my-8  ${message.content.includes("Début du prérapport") ? 'hidden-message' : ''}`} >
                  {message.role === 'assistant' && (
                      <img src="https://i.ibb.co/PcjhQnX/6.png" className="h-16 w-16 " alt=""/>
                  )}
                  <div className={`text-left p-5 rounded-xl text-sm md:text-base
                ${message.role === 'user' ? 'bg-gradient-to-r from-[#AC98FF] to-[#AC98FF] rounded-tr-none shadow-[#265279] shadow-perso' : 'bg-gradient-to-r from-[#2D547D] to-[#86A9CD] rounded-tl-none shadow-[#265279] shadow-perso'}`}>
                    {Array.isArray(message.content) ? message.content.map((contentItem, contentIndex) => {
                      if (contentItem.type === "text") {
                        return <p><ReactMarkdown key={contentIndex}>{contentItem.text}</ReactMarkdown></p>;
                      } else if (contentItem.type === "image_url") {
                        return <div id="photoContainer">
                          <img id="photoUser" key={contentIndex} src={contentItem.image_url.url}
                               alt={`Uploaded Content ${contentIndex}`}/>
                        </div>;
                      }
                      return null;
                    }) : <p><ReactMarkdown>{message.content}</ReactMarkdown></p>}
                  </div>
                  {message.role === 'user' && (
                      <img src="https://i.ibb.co/b7yNknT/human.png" className="h-14 w-14" alt=""/>
                  )}
                </div>
            ))}
            {isLoading && (
                <div className="loader"></div>
            )}
            <div ref={messagesEndRef}/>
          </div>
          <div className="flex flex-wrap fixed bottom-24 space-x-2 px-4">
            {uploadedImageUrls.map((url, index) => (
                <div key={index} className="relative m-2">
                  <img src={url} alt={`Uploaded ${index}`} className="w-auto h-[60px] object-cover"/>
                  <button
                      className="absolute top-1 right-1 bg-white bg-opacity-70 rounded-full text-sm p-1 cursor-pointer"
                      onClick={() => removeImage(url)}
                  >&times;</button>
                </div>
            ))}
            {largeImageUrls.map((url, index) => (
                <div key={index} className="relative m-2 border-4 border-red-500">
                  <img src={url} alt={`Large ${index}`} className=" w-auto h-[60px] " id="imgTooHeavy"/>
                  <button
                      className="absolute top-1 right-1 bg-white bg-opacity-70 rounded-full text-sm  h-5 w-5 p-1 cursor-pointer"
                      onClick={() => removeImage(url)}
                  >&times;</button>
                </div>
            ))}
          </div>
          <div className="bg-blue-300 relative">
            <form
                className="py-5 px-4 m-auto flex flex-row w-full fixed bottom-0 left-0 right-0 border-2 border-black"
                onSubmit={(e) => handleSend(e, inputValue)}>
              <input
                  className="w-full rounded-lg border-[1px] border-[#2D547D] h-14 m-auto bg-grey-400 pl-28 pr-20 text-[#2D547D] "
                  name="question"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Posez vos questions ici"/>
              <div className="m-auto flex flex-row">
                <button type="submit" className=" w-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#2D547D"
                       className="w-9 h-9 text-gray-500 hover:text-gray-800 absolute bottom-8 right-8">
                    <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517
                      60.517 0 003.478 2.405z"/>
                  </svg>
                </button>
                <Pdfgenerator messages={messages} sentImageUrls={sentImageUrls} setShowForm={setShowForm} setShowModal={setShowModal} setIsOver={setIsOver}/>
              </div>
              <div>
                <input type="file"
                       id={"file"}
                       name={"file"}
                       accept={"image/png, image/jpeg, image/jpg"}
                       onInput={handleImageChange}
                       multiple={true}
                       className="hidden"
                />
                <label htmlFor="file" className={`w-auto absolute left-6 bottom-8`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                       stroke="#2D547D"
                       className={`w-9 h-9 ${photosButtons ? '' : 'pointer-events-none opacity-20'}`}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"/>
                  </svg>
                </label>
                <svg className={`absolute left-16`} xmlns="http://www.w3.org/2000/svg" width="2" height="56" version="1.1">
                  <line x1="1.5" y1="10" x2="1.0" y2="45" stroke="#2D547D" strokeWidth="2.5"/>
                </svg>
              </div>
            </form>
          </div>
        </div>
        {showModal && <Modal onClose={() => setShowModal(false)} />}
      </div>
  );
}

export default App;
