import React from 'react';

const Modal = ({ onClose }) => {
    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="bg-[#AC98FF] p-6 rounded shadow-lg">
                <h2 className="text-xl font-bold mb-4">Déclaration Générée</h2>
                <p>Merci pour ces informations. Votre déclaration a été générée et enregistrée sur votre appareil. Veuillez vous rendre dans la section Téléchargement pour y accéder.</p>
                <br/>
                <p>Votre document a également été transmis au gestionnaire de votre dossier.</p>
                <button onClick={onClose} className="mt-4 bg-[#265279] text-white px-4 py-2 rounded">Fermer</button>
            </div>
        </div>
    );
};

export default Modal;
