import { useEffect, useState } from 'react';

const useImageUpload = (maxSizeMB = 2) => {
  const [uploadedImageUrls, setUploadedImageUrls] = useState([]);
  const [largeImageUrls, setLargeImageUrls] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const API_IMG = "bb0dabcc207ba91ae21eb2f26b2b7fc6";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const resetModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  }

  const handleImageChange = async (event) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const maxSize = maxSizeMB * 1024 * 1024; // Convert MB to bytes
      const newLargeImageUrls = [];

      for (const file of files) {
        if (file.size > maxSize) {
          newLargeImageUrls.push(URL.createObjectURL(file));
        }
      }

      setLargeImageUrls(prevUrls => [...prevUrls, ...newLargeImageUrls]);

      const validFiles = files.filter(file => file.size <= maxSize);

      const urls = await Promise.all(validFiles.map(async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
          const response = await fetch(`https://api.imgbb.com/1/upload?key=${API_IMG}`, {
            method: 'POST',
            body: formData
          });
          const data = await response.json();
          return data.data.url;
        } catch (error) {
          console.error('There was an error with the IBB:', error);
          setErrorMessage('Une erreur est survenue lors du téléchargement de l\'image.');
          setIsModalOpen(true);
          return null;
        }
      }));

      setUploadedImageUrls(prevUrls => [...prevUrls, ...urls.filter(url => url !== null)]);
    }
  };

  const removeImage = (urlToRemove) => {
    setUploadedImageUrls(prevUrls => prevUrls.filter(url => url !== urlToRemove));
    setLargeImageUrls(prevUrls => prevUrls.filter(url => url !== urlToRemove));
  };

  useEffect(() => {
    if (uploadedImageUrls.length > 0) {
      setErrorMessage(''); // Clear error message when images are successfully uploaded
    }
  }, [uploadedImageUrls]);

  useEffect(() => {
    if (!isModalOpen) {
      resetModal(); // Reset modal state when it is closed
    }
  }, [isModalOpen]);

  return {
    uploadedImageUrls,
    largeImageUrls,
    setUploadedImageUrls,
    handleImageChange,
    errorMessage,
    isModalOpen,
    setIsModalOpen,
    setErrorMessage,
    resetModal,
    removeImage
  };
};

export default useImageUpload;
