import { systemMessage } from "../systemMessage";
import axios from 'axios';

const useSendMessage = (
    inputValue,
    setInputValue,
    setIsLoading,
    setSentImageUrls,
    setUploadedImageUrls,
    uploadedImageUrls,
    messages,
    setMessages
) => {

  const handleSend = async (event, text) => {
    event?.preventDefault();
    setIsLoading(true);

    // Préparer le contenu du message incluant texte et images
    let messageContent = [];
    if (text.trim()) {
      messageContent.push({ type: "text", text: text.trim() });
    }

    uploadedImageUrls.forEach((url) => {
      messageContent.push({
        type: "image_url",
        image_url: { url },
      });
    });

    const newMessage = {
      role: "user",
      content: messageContent,
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setUploadedImageUrls([]);
    setInputValue('');
    //const userId = localStorage.getItem('userId')
    // Enregistrer le message utilisateur dans la base de données
    //try {
    //  await axios.post('http://localhost:3001/messages', {
    //    user: 'utilisateur', // Remplace par la logique d'authentification de ton application
    //    content: messageContent[0].text,
    //    role: 'user',
    //    userId: 43

      //});
    //} catch (error) {
     // console.error('Erreur lors de l\'enregistrement des messages', error);
    //}

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_API_KEY_OAI}`,        
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          model: "gpt-4-vision-preview",
          // model: "gpt-4o",
          messages: [systemMessage, ...newMessages],
          max_tokens: 2500,
          temperature: 0.7,
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`status: ${response.status}, Message : ${errorData.error.message}`);
      }

      const data = await response.json();
      const assistantMessage = { role: "assistant", content: data.choices[0].message.content };

      // Ajouter la réponse de l'assistant aux messages
      setMessages(prev => [...prev, assistantMessage]);

      // Enregistrer le message assistant dans la base de données
      // try {
      //   await axios.post('http://localhost:3001/messages', {
      //     user: 'Assistant', // Identifiant pour l'assistant
      //     content: assistantMessage.content,
      //     role: 'assistant'
      //   });
      //   // console.log(assistantMessage.content);
      // } catch (error) {
      //   console.error('Error saving assistant message', error);
      // }

    } catch (error) {
      console.error('There was an error with the ChatGPT API:', error);
    } finally {
      setIsLoading(false);
    }

    setSentImageUrls(prev => [...prev, ...uploadedImageUrls]);
  };

  return { handleSend };
};

export default useSendMessage;
